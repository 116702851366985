import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Navbar from "../../Components/commonComponents/navbar/Navbar";
import ArrowBack from "../../assets/images/favoriteProperty/arrow-back.svg";
import CardImage from "../../assets/images/card-image.svg"
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
// import FormControl from '@mui/material/FormControl';
// import { MenuItem, Select } from '@mui/material';
// import InputLabel from '@mui/material/InputLabel';
// import FormInput from '../../Components/Inputs/FormInput/FormInput';
import url from "../../utils/services/urls.json"
import { postMethodWithToken } from '../../utils/services/apis';
import "./CheckoutPayment.scss"
import { getUserId } from '../../utils/services/common';
import { toast } from 'react-toastify';

const CheckoutContainer = styled.div`
background: rgb(242, 242, 247);
height:100vh;
font-family: Proxima_nova_reg;
`
const paypalOptions = {
    // 'client-id': 'AbysTaXRkDPWv-JqxyNvz6AFQzvCYiq-Mftc2y2x3pJ3XPq6dj5hZL2kv3tnt1ZK0oBe98OObknnagJj',
    // 'client-id': "AcMXdFC-3VdF1HtFOJY8nEoF0CRuIcdMoVTrajLnwaR8nQQK6ze_ueDxevuKovSAM80lWUj283mpnCCl",
    'client-id':"AQRvSUPjROQYwZnk-TK9YR4rQ0a1JOyuzuhloudr75QbDKWXnoqH6W7r9z-VaibaogoawEg-1H89-iJ5",
    'disable-funding': 'card',
    currency: 'USD',
};

const CheckoutPayment = () => {
    const navigate = useNavigate();
    const params = useParams()
    const location = useLocation()
    const subscriptionDetailsListing = localStorage.getItem("subscriptionDetail") !== undefined ? JSON?.parse(localStorage.getItem("subscriptionDetail")) : "";
    // const [couponCode, setCouponCode] = useState("")
    // const [subscriptionPackage, setSubscriptionPackage] = useState("")
    const subscriptionPrice = localStorage.getItem("subscriptionPrice") ? localStorage.getItem("subscriptionPrice") : ""

    // const handleCouponCodeChanges = (event) => {
    //     setCouponCode(event.target.value)
    // }

    // const handlePackageChanges = (event) => {
    //     setSubscriptionPackage(event.target.value)
    // }

    var intervalId;

    const handleTransactionPayment = async (verifyValue) => {
        let body = {
            // "propertyMaster": params.id,
            "master_sub_id": +localStorage.getItem("masterSubscrptionManagerId"),
            "transaction_status": verifyValue?.status,
            "payment_id": verifyValue?.purchase_units?.[0]?.payments?.captures?.[0]?.id,
            "email_address": localStorage.getItem("userEmail"),
            "payer_id": verifyValue?.payer?.payer_id
        }
        await postMethodWithToken(url.postTransactionVerify, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                if (location.pathname.includes('/Dashboard/Checkout/Payment')) {
                    intervalId = setInterval(() => {
                        if (location.pathname.includes('/Dashboard/Checkout/Payment')) {
                            handleVerifyPayment(verifyValue)
                        }
                    }, 5000);
                }
            }
        })
    }

    useEffect(() => {
        // if (location.pathname !== '/Dashboard/ManageSubscription/PaymentDetail') {
        return () => clearInterval(intervalId);
        // }
    }, [])

    const handleVerifyPayment = async (value) => {
        var paymentBody = {
            "user_id": +getUserId(),
            "payment_id": value?.purchase_units?.[0]?.payments?.captures?.[0]?.id,
            "master_sub_id": +localStorage.getItem("masterSubscrptionManagerId")
        }
        if (subscriptionDetailsListing !== null) {
            paymentBody["is_cancel"] = false
        }
        await postMethodWithToken(url.PostVerifyPayment, paymentBody).then((response) => {
            if (response.status === 200 || response.status === 201) {
                toast.success(response?.data?.name)
                if (params.assignList) {
                    navigate(`/Dashboard/AssignedListings`)
                    localStorage.removeItem("masterSubscrptionManagerId")
                } else {
                    navigate(`/Dashboard/ManageSubscription`)
                    localStorage.removeItem("masterSubscrptionManagerId")
                }
            }
        })
    }
    return (
        <div style={{ overflowX: 'hidden' }} className='searchdetail'>
            <div style={{ background: '#f2f2f7', height: 'fit-content' }}>
                <Navbar />
            </div>
            <CheckoutContainer>
                <Row style={{ padding: "80px 25px 20px 25px" }}>
                    <Col lg={1}>
                        <div className="arrow-box-paymennt" onClick={() => navigate(-1)}>
                            <img src={ArrowBack} alt="" />
                        </div>
                    </Col>
                    <Col lg={5}>
                        <div className="payment-detail">
                            <div className="payment-heading" >Checkout</div>
                            <div className="d-flex justify-content-center">
                                <div className="method-heading mt-4">Payment Method</div>
                            </div>
                            <div className="d-flex justify-content-center mt-3">
                                {/* <div className="card-container">
                                    <div className="d-flex justify-content-center mt-2"><img src={CardImage} alt="CardImage" /></div>
                                    <div className="d-flex justify-content-center credit-heading mt-1">Credit Card</div>
                                </div> */}
                                <div style={{ marginLeft: "10px" }}>
                                    <PayPalScriptProvider options={paypalOptions} style={{ minHeight: '40px' }}>
                                        <PayPalButtons
                                            createOrder={(data, actions) => {
                                                return actions.order.create({
                                                    "intent": "CAPTURE",
                                                    purchase_units: [
                                                        {
                                                            amount: {
                                                                value: subscriptionPrice,
                                                            },
                                                        },
                                                    ]
                                                });
                                            }}
                                            style={{
                                                color: "white",
                                                height: 55,
                                            }}
                                            onApprove={async (data, actions) => {
                                                const order = await actions.order.capture();
                                                if (order.status === "COMPLETED") {
                                                    // handleVerifyPayment(order)
                                                    handleTransactionPayment(order)
                                                }
                                            }
                                            }

                                        />
                                    </PayPalScriptProvider>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={5}>
                        <div className="listing-detail">
                            <div className="listing-main-heading">Payment details</div>
                            <div className="payment-card">
                                {/* <FormInput required={true} name={'Coupon Code'} style={{ width: "430px" }} value={couponCode} onChange={(e) => handleCouponCodeChanges(e)} showText={"Apply Coupon"} /> */}
                                <Row>
                                    {/* <Col lg={9}>
                                        <FormControl sx={{ minWidth: 250 }}>
                                            <InputLabel id="demo-simple-select-label">Subscription Package</InputLabel>
                                            <Select
                                                value={subscriptionPackage}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                onChange={(e) => handlePackageChanges(e)}
                                                sx={{
                                                    boxShadow: "none",
                                                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                                    {
                                                        border: 0,
                                                    },
                                                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                                    {
                                                        border: 0,
                                                    },
                                                }}
                                            >
                                                <MenuItem value={10}>Mid to Long Term 10 listings</MenuItem>
                                                <MenuItem value={20}>Mid to Long Term 5 listings</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Col> */}
                                    <Col xs={9} sm={9} md={9} lg={9}><div className="payment-descrption mt-2 mb-3">Subscription Package</div></Col>
                                    <Col xs={1} sm={1} md={1} lg={1}><div className="price-payment mt-2 mb-3">${subscriptionPrice}</div></Col>
                                    {/* <Col lg={9}><div className="payment-descrption mt-4 mb-3">Processing Fee</div></Col>
                                    <Col lg={1}><div className="price-payment mt-4 mb-3">$</div></Col> */}
                                    <hr />
                                    <Col xs={9} sm={9} md={9} lg={9}><div className="payment-descrption mt-1">Total payable amount</div></Col>
                                    <Col xs={1} sm={1} md={1} lg={1}><div className="price-payment mt-1">${subscriptionPrice}</div></Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </CheckoutContainer>
        </div>
    )
}

export default CheckoutPayment
